<template>
  <div id="app">
    <el-container>
      <el-main>
        <el-table :data="tableData" border="true" class="table-style">
          <el-table-column prop="id" label="ID" sortable width="70px" />
          <el-table-column prop="userName" label="姓名" width="100px" />
          <el-table-column prop="userSex" label="性别" width="50px" />
          <el-table-column prop="userTel" label="手机号" width="120px" />
          <el-table-column prop="address" label="地址" />
          <el-table-column prop="estimateDate" label="预计来校时间" sortable />
          <el-table-column prop="AccurateDate" label="来访时间段" width="120px" />
          <el-table-column prop="userOrder" label="来访人次" width="100px"/>
          <el-table-column prop="submitTime" label="提价时间" />
          <el-table-column prop="sendSMSTime" label="短信发送时间" />
          <el-table-column prop="sendSMSStatus" label="短信发送状态" />
        </el-table>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getUser } from "./api/api";
export default {
  name: 'app',
  data() {
    return {
      tableData:[]
    }
  },
  methods:{
    getmsg(){
      getUser().then((data)=>{
        data.data.forEach((element) => {
            let tableObj = new Object;
            tableObj.id = parseInt(element.id);
            tableObj.userName = element.userName;
            tableObj.userSex =  element.userSex?'男':'女';
            tableObj.userTel = element.userTel;
            tableObj.address = element.address;
            tableObj.estimateDate = element.estimateDate;
            tableObj.submitTime = element.submitTime;
            tableObj.AccurateDate = element.AccurateDate;
            tableObj.userOrder = element.userOrder;
            tableObj.sendSMSTime = element.sendSMSTime;
            tableObj.sendSMSStatus = element.sendSMSStatus;
            this.tableData.push(tableObj);
            this.loading = false;
        });
      })
    }
  },
  created(){
    this.getmsg();
  }

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.el-table td.el-table__cell{
  padding: 10px 0;
}
.el-table th.el-table__cell>.cell{
  text-align: center;
  padding: 0;
}
.el-table td.el-table__cell div{
  text-align: center;
}
</style>
